<template>
    <div class="siteSet">
        <div class="left-nav">
            <div class="nav">
                <div class="li" :class="{selected:navIndex==index}"  v-for="(item,index) in reportNavData" :key="item">
                    <div class="title verticalCenter" @click="navClick(index)">
                        <i class="iconfont" :class="item.icon"></i>
                        {{item.title}}
                    </div>
                    <el-collapse-transition>
                        <div class="children-nav" v-show="navIndex==index">
                            <div class="li" 
                            v-for="childre in item?.childre" :key="childre"
                            :class="{selected:navChildreIndex==childre.key}" 
                            v-show="!(childre.isShow===false)"
                            @click="addFrame(childre)"
                            >{{childre.title}}</div>
                        </div>
                    </el-collapse-transition>
                </div>
            </div>
        </div>
        <div class="right-frame-box">
            <tabs class="frame-tabs" v-model="frameIndex">
                <div class="frame-nav">
                    <div class="scroll-box" v-mouse-scroll>
                        <tab-nav class="li" v-for="(item,index) in tabsFrameData" :key="item">
                            {{item.navTitle}}<i class="ico-close iconfont" @click.stop="delFrame(index)">×</i>
                        </tab-nav> 
                    </div>
                </div>
                <div class="frame-pane">
                    <tab-pane v-for="(item,index) in tabsFrameData" :key="item">
                        <div class="component-box" v-if="!item.isHttp">
                            <component v-if="item.frameUrl" :key="item.componentKey" v-on:reload="item.componentKey=!item.componentKey" :is="item.frameUrl" :data="item.data" v-on:close="delFrame(index)"></component>
                            <el-empty v-else description="加急开发中,请耐心等待"></el-empty>
                        </div>
                        
                        <iframe v-if="item.isHttp" :src="item.frameUrl"></iframe>
                    </tab-pane>
                </div>
            </tabs>
            
        </div>
    </div>
</template>

<script>
import siteSetModel from './siteSetModel'

export default {
    components:siteSetModel.components,
    name:"siteSet",
    data(){
        return {
            /**是否是pc 设备 */
			isWindow:this.$webBrowser.isMobile.Windows,
            navIndex:0,
            navChildreIndex:null,
            reportNavData:[{
                key:'zdpz',title:"站点配置",icon:'icon-zhandianpeizhi--fenlei',
                childre:[
                    {key:'baseConfig',title:"基本配置",url:"baseSetup"},
                    {key:'redCardConfig',title:"读卡器配置",url:"entityCardsSetup"},
                    {key:'cashboxConfig',title:"钱箱配置",url:"cashboxSetup"},
                    {key:'electronicScaleConfig',title:"电子秤配置",url:"electronicScaleSetup"},
                    {key:'readCardOrderConfig',title:"读卡点菜",url:"readCardOrderSetup"},
                    {key:'subscribeVoiceSetup',title:"语音播报",url:"subscribeVoiceSetup",isShow:this.$webBrowser.isMobile.Windows},
                    {key:'aiSetup',title:"AI识别",url:"aiSetup",isShow:this.$webBrowser.isMobile.Android},
                ]
            } ],
            tabsFrameData:[],
            frameIndex:0
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.$nextTick(()=>{
            
        });
    },
    watch:{
        frameIndex(newValue){
            this.navChildreIndex=this.tabsFrameData[newValue]?.key;
        }
    },
    methods:{
        navClick(index){
            if(this.navIndex!=index){
                this.navIndex=index;
            }else{
                this.navIndex=null;
            }
        },
        delFrame(index){
            this.tabsFrameData?.splice(index,1);
            if(index==this.frameIndex){
                if(this.frameIndex>0){
                    this.frameIndex--;
                }
                this.navChildreIndex=this.tabsFrameData[this.frameIndex]?.key;
            }
        },
        addFrame(data){
            console.log(data)
            this.navChildreIndex=data.key;
            let index= this.tabsFrameData?.findIndex((item)=>item.key==data.key);
            if(index>=0){
                this.frameIndex=index;
            }else{
                if(this.tabsFrameData.length>=10){
                    this.$message.warning('页签最多开10个,请先清除不用的在打开');
                    return;
                }
                let item={key:data.key,navTitle:data.title,frameUrl:'',isHttp:false,data:data.data};
                if(/^http/g.test(data.url)){
                    item.frameUrl=data.url;
                    item.isHttp=true;
                }else{
                    item.frameUrl=siteSetModel.getComponent(data.url);
                }
                this.tabsFrameData.push(item);
                this.frameIndex=this.tabsFrameData.length-1;
            }
        }
    }
}
</script>

<style lang='scss'>
@import "./siteSet.scss";
</style>