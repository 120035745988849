import { defineAsyncComponent } from "vue"

/*异步获取模板 */
const loadComponent=(name)=>{
     return defineAsyncComponent(() =>
        import('/src/components/home/setup/siteSet/siteSetModel/'+name+'/'+name+'.vue')
    )
}

/***纪录所有 模板 */
export default {
    /**报表模板 */
    components:{},
    /**模板名称 @param name 不区分大小写和- _符号*/
    getComponent(name){
        name=name?.toLocaleLowerCase().replace(/[-_]/g,'');
        let component=({
            /**读卡器配置 */
            "entitycardssetup":{
                tagName:"entity-cards-set-up",
                name:'entityCardsSetUp'
            },
            /**基础配置 */
            "basesetup":{
                tagName:"base-setup",
                name:"baseSetup"
            },
            /**钱箱配置 */
            "cashboxsetup":{
                tagName:"cashbox-setup",
                name:"cashboxSetup"
            },
            /**电子秤配置 */
            "electronicscalesetup":{
                tagName:"electronic-scale-setup",
                name:"electronicScaleSetup"
            },
            /**读卡点菜配置 */
            "readcardordersetup":{
                tagName:"read-card-order-setup",
                name:"readCardOrderSetup"
            },
            /**订阅语音播报功能 配置 */
            "subscribevoicesetup":{
                tagName:"subscribe-voice-setup",
                name:"subscribeVoiceSetup"
            },
            /**AI识别功能 配置 */
            "aisetup":{
                tagName:"ai-setup",
                name:"aiSetup"
            }
        })[name];
        if(component){
            if(!this.components[component.name]){
                this.components[component.name]=loadComponent(component.name);
            }
            return component.tagName;
        }
        return;
    }
}